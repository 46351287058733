import React from "react";
import "./footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <p style={{ alignContent: "center" }}>&copy; 2022, Peter Lewandowski</p>
    </div>
  );
}
